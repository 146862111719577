import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import TestimonialContainer from '@/components/home/TestimonialContainer';

const app = createApp(App);

app.config.globalProperties.$postJson = async (url, data = {}) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  return json;
};

app.component('TestimonialContainer', TestimonialContainer);

app.use(store);
app.use(router);
app.mount('#app');
