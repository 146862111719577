<template>
  <div class="testimonial-box" :class="this.type">
    <div class="rating">
      <img
        v-for="n in testimonial.rating"
        :key="n"
        src="@/assets/home/yellow_star.svg"
      />
      <img
        v-for="n in 5 - testimonial.rating"
        :key="n"
        src="@/assets/home/gray_star.svg"
      />
    </div>
    <p class="content">{{ testimonial.testimonial }}</p>
    <p class="date">{{ testimonial.date }}</p>
    <p class="nickname">{{ testimonial.nickname }}</p>
  </div>
</template>

<script>
export default {
  props: ['testimonial', 'type'],
};
</script>

<style scoped lang="scss">
* {
  font-weight: 700;
  text-align: left;
}

.testimonial-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32rem;
  height: 32rem;
  background: #f8f9fa;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.075);
  border-radius: 1.5rem;
  background-color: #f8f9fa;
}
.testimonial-box.pc:nth-of-type(even) {
  background-color: #fffceb;
}

.rating {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 2.4rem;
}

.content {
  margin: 1.6rem 3.2rem 0 3.2rem;
  align-self: stretch;
  flex-grow: 1;
  font-size: 2rem;
  white-space: pre-wrap;
}

.date {
  margin-left: 3.2rem;
  margin-bottom: 0.8rem;
  align-self: flex-start;
  font-size: 1.2rem;
  color: #868e96;
}

.nickname {
  white-space: pre;
  margin-bottom: 2.4rem;
  margin-left: 3.2rem;
  align-self: flex-start;
  font-size: 1.2rem;
  color: #868e96;
}

/**************************/
/* BELOW 800px */
/**************************/

@media (max-width: 50em) {
  .testimonial-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonial-outer-box {
    position: absolute;
    left: 50%;
  }
}
</style>
