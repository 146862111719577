<template>
  <router-view />
</template>

<style>
/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Regular: 400
Bold: 700
Extra-Bold: 800

- Line heights
NanumSquareRound: 1.5
Jua: 1.2

- Letter spacing
-0.5px
-1px


--- 02 COLORS

- Primary: #ffc107
- Tints:
#fff9db
#EBFBEE

- Shades: 
#fab005

- Accents: #d9480f
- Greys

#f1f3f5 
#adb5bd 연한글씨
#868E96
#212529 기본 글씨체

--- 05 SHADOWS

0 0.4rem 1rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: maximum
Medium: 1.5rem

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

* {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #212529;
  box-sizing: border-box;
  font-family: 'NanumSquareRound', sans-serif;
  line-height: 1.5;
}

html {
  font-size: 62.5%;
}

/**************************/
/* BELOW 1008px */
/**************************/

@media (max-width: 63em) {
  html {
    font-size: 50%;
  }
}
</style>
